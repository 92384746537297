import React, { FC } from 'react';
import { GatsbyImage, getImage, IGatsbyImageData, StaticImage } from "gatsby-plugin-image"


import './Travel.css'
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { useTranslation } from 'react-i18next';


type TravelProps = {
    left_to_right: boolean,
    image: IGatsbyImageData,
    features: String[],
    linkButton: JSX.Element,
    price: string,
    duration: string
};



const Travel: FC<TravelProps>  = ({left_to_right, image, features, linkButton , price, duration}) => {
    const {width} = useWindowDimensions();
    const { t } = useTranslation('translation', { keyPrefix: 'travelBlocks' });



    if (left_to_right && width > 768){
        return (
            <div className='travel_container grow wrapped-row' >
                    <div className='relative_container'>
                      <GatsbyImage style={{maxWidth: '600px'}} alt='' image={image}></GatsbyImage>
                      <div className='horizontal_gradient_absolute'>
                       </div>
                  </div>
                <div className='feature_list_horizontal'>
                    <h5 className='travel_header'>{features[0]}</h5>
                    <ul className='feature_block'>
                        <div className='row'>
                            <span id='checkmark'></span>
                            <li >{features[1]}</li>
                        </div>
                        <div className='row'>
                            <span id='checkmark'></span>
                            <li >{features[2]}</li>
                        </div>
                        <div className='row'>
                            <span id='checkmark'></span>
                            <li >{features[3]}</li>
                        </div>
                        <div className='row'>
                            <span id='checkmark'></span>
                            <li >{features[4]}</li>
                        </div>
                    </ul>
                    <hr></hr>
                    <div className='text_row'>
                        <StaticImage
                            src='../../icons/clock.svg'
                            quality={95}
                            formats={['auto', 'webp', 'avif']}
                            loading='eager'
                            alt='Intro banner'
                            className='custom_icon'
                        />
                        <div>{t('duration')}{duration} {t('days')}</div>
                    </div>

                    <div className='text_row'>
                        <StaticImage
                            src='../../icons/money.svg'
                            quality={95}
                            formats={['auto', 'webp', 'avif']}
                            loading='eager'
                            alt='Intro banner'
                            className='custom_icon'
                        />
                        <div>{t('from')} €{price}</div>
                    </div>
                    <hr></hr>
                    {/* <div className='btn_container'>
                        // <button type='button' className='read_more_btn'>Meer lezen</button>
                    </div> */}
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        {linkButton}
                    </div>
                </div>

            </div>
        );

    }

    else {
        return (
            <div className='travel_container grow' style={{flexDirection: 'column'}}>
                <div className='travel_text' style={{display: 'flex', flexDirection: 'column' , backgroundColor: '#ffffff', width: '', padding: ' 0px 15px'}}>
                    <h5 className='travel_header'>{features[0]}</h5>
                    <ul>
                        <div className='row'>
                            <span id='checkmark'></span>
                            <li >{features[1]}</li>
                        </div>
                        <div className='row'>
                            <span id='checkmark'></span>
                            <li >{features[2]}</li>
                        </div>
                        <div className='row'>
                            <span id='checkmark'></span>
                            <li >{features[3]}</li>
                        </div>
                        <div className='row'>
                            <span id='checkmark'></span>
                            <li >{features[4]}</li>
                        </div>
                    </ul>
                    <hr></hr>
                    <div style={{display: 'flex', flexDirection: 'row',  margin: '0 0 5px 20px'}}>
                        <StaticImage
                            src='../../icons/clock.svg'
                            quality={95}
                            formats={['auto', 'webp', 'avif']}
                            loading='eager'
                            alt='Intro banner'
                            style={{ width: '25px', marginRight: '10px',opacity: '0.5' }}
                        />
                        <div>{t('duration')}{duration} {t('days')}</div>
                    </div>

                    <div style={{display: 'flex', flexDirection: 'row',margin: '0 0 5px 20px'}}>
                        <StaticImage
                            src='../../icons/money.svg'
                            quality={95}
                            formats={['auto', 'webp', 'avif']}
                            loading='eager'
                            alt='Intro banner'
                            style={{ width: '25px', marginRight: '10px',opacity: '0.5' }}
                        />
                        <div>{t('from')} €{price}</div>
                    </div>
                    
                    <hr></hr>
                    {/* <div className='btn_container'>
                        <button style={{margin: '13px 0'}} className='read_more_btn'>Meer lezen</button>
                    </div> */}
                    <div style={{display: 'flex', justifyContent: 'center' , marginBottom: '23px'}}>
                        {linkButton}
                    </div>

                </div>
                  <div style={{position: 'relative', width: '100%'}}>
                      <GatsbyImage style={{maxWidth: '538px', maxHeight: '418px'}} alt='' image={image}></GatsbyImage>
                      <div style={{width: '100%', position: 'absolute', top: '0',
                            height:'395px',backgroundImage: 'linear-gradient(to top, rgba(0,0,0,0) 60%, rgba(255,255,255,1) 95%)'}}>
                       </div>
                  </div>
            </div>
            
        );
    }

    
}

export default Travel;