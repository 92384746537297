import React, { FC } from 'react';

import './Quote.css';

type QuoteProps = {
    quote: string,
    quote_owner: string
};

const Quote: FC<QuoteProps> = ({ quote, quote_owner }) => {
    return (
        <div className='quote'>
            <div className='white_block_behind' />
            <div className='white_block_front'>
                <div>
                    <p className='quote_text'>{quote}</p>
                    <p className='quote_owner'>{quote_owner}</p>
                </div>
            </div>
        </div>
    );
}

export default Quote;